
import { dateFrancaise, variables } from "@/plugins/variables"
import { Machine, Pneu } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import NonAcces from "../components/NonAcces.vue"

export default Vue.extend({
  name: "Pneu",
  components: { NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      dictionnaire: [] as string[],
      pneus: [] as Pneu[],
      unPneu: new Pneu(),
      unPneuTampon: new Pneu(),
      machines: [] as Machine[],
      dimensionPossible: [],
      etatPossible: [] as any[],
      actionPossible: [] as string[],
      dialogueNouveauPneu: false,
      dialogueModifierPneu: false,
      recherchePossible: [] as string[],
      recherche: "",
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios
        .get(this.$store.state.ajaxurl + "Pneu.php", {
          params: {
            action: "INITIALISER_PNEU"
          }
        })
        .then(response => {
          this.pneus = response.data.pneus.map((p: Pneu) => {
            return new Pneu(p)
          })
          this.machines = response.data.machines.map((m: Machine) => {
            return new Machine(m)
          })
          this.dimensionPossible = response.data.lesDimensionPneu
          this.majRecherchePossible()
          this.desactivationRecherche()
        })
    },
    recherchePneu() {
      this.pneus = this.pneus.sort((a: Pneu, b: Pneu) => {
        if (a.getCode() == this.recherche && b.getCode() != this.recherche) return -1
        if (a.getCode() != this.recherche && b.getCode() == this.recherche) return 1
        else return 0
      })
    },
    desactivationRecherche() {
      this.pneus = this.pneus.sort((a: Pneu, b: Pneu) => {
        if (a.getCode() < b.getCode()) return -1
        if (a.getCode() > b.getCode()) return 1
        else return 0
      })
    },
    majRecherchePossible() {
      this.recherchePossible = this.pneus.map((p: Pneu) => {
        return p.getCode()
      })
    },
    designationMachine(sm_id: number) {
      const monteOu = this.machines.find((m: Machine) => m.getId() == sm_id)
      if (monteOu !== undefined) {
        return monteOu.getDesignation()
      } else {
        return ""
      }
    },
    etatPneus(sp_etat: string) {
      switch (sp_etat) {
        case "N":
          return this.etatPossible[0].text
        case "H":
          return this.etatPossible[1].text
        case "D":
          return this.etatPossible[2].text
        case "U":
          return this.etatPossible[3].text
        case "C":
          return this.etatPossible[4].text
        case "R":
          return this.etatPossible[5].text
      }
    },
    actionPneus(sp_action: string) {
      switch (sp_action) {
        case "M":
          return this.actionPossible[0]
        case "D":
          return this.actionPossible[1]
      }
    },
    ouvertureDialogueNouveauPneu() {
      this.dialogueNouveauPneu = true
      this.unPneu = new Pneu()
    },
    ouvertureDialogueModification(unPneu: Pneu) {
      this.dialogueModifierPneu = true
      this.unPneu = unPneu
      this.unPneuTampon = new Pneu(unPneu)
    },
    fermerNouveauPneu() {
      this.dialogueNouveauPneu = false
      this.unPneu = new Pneu()
    },
    fermerModificationPneu() {
      this.dialogueModifierPneu = false
      const index = this.pneus.findIndex((p: Pneu) => p.getId() == this.unPneuTampon.getId())
      if (index != -1) {
        this.pneus.splice(index, 1, this.unPneuTampon)
        this.unPneuTampon = new Pneu()
        this.unPneu = new Pneu()
      }
    },
    enregistrerPneu() {
      if (this.unPneu.getId() == "") {
        const laDate = new Date().getTime() + ""
        this.unPneu.setId(laDate)
      }
      axios
        .post(this.$store.state.ajaxurl + "Pneu.php", {
          action: "ENREGISTRER_PNEU",
          pneu: this.unPneu
        })
        .then(() => {
          if (this.dialogueNouveauPneu == true) {
            this.pneus.push(this.unPneu)
          }
          this.dialogueNouveauPneu = false
          this.dialogueModifierPneu = false
          this.majRecherchePossible()
          this.unPneu = new Pneu()
          this.initialiser()
        })
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    dateFrancaise(date: string) {
      dateFrancaise(date)
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Nouveau", //0
            "Enregistrer", //1
            "Numéros de série", //2
            "Dimension", //3
            "État", //4
            "Machine", //5
            "Nouveau pneu", //6
            "Modifier pneu", //7
            "Historique", //8
            "Modifier", //9
            "Date", //10
            "Action", //11
            "Monté où", //12
            "Commentaire", //13
            "Numéros de pneu" //14
          ]
          this.etatPossible = [
            { value: "N", text: "Neuf" },
            { value: "H", text: "HS" },
            { value: "D", text: "Défectueux" },
            { value: "U", text: "Usagé" },
            { value: "C", text: "Réchappé" },
            { value: "R", text: "Réparé" }
          ]
          this.actionPossible = ["Monter", "Démonter"]
          break
        case "AL":
          this.dictionnaire = [
            "Neu", //0
            "Registrieren", //1
            "Seriennummer", //2
            "Abmessungen", //3
            "Zustand", //4
            "Maschine", //5
            "Neuer Reifen", //6
            "Reifen ändern", //7
            "Geschichte", //8
            "Bearbeiten", //9
            "Datum", //10
            "Aktion", //11
            "Montiert wo", //12
            "Kommentar", //13
            "Reifencode" //14
          ]
          this.etatPossible = [
            { value: "N", text: "Neu" },
            { value: "H", text: "Schrott" },
            { value: "D", text: "Defekt" },
            { value: "U", text: "Gebraucht" },
            { value: "C", text: "Runderneuert" },
            { value: "R", text: "Repariert" }
          ]
          this.actionPossible = ["Montieren", "Demontieren"]
          break
      }
    },
    autAdministration() {
      return variables.autorisation.includes("Administration")
    },
    autPneu() {
      return variables.autorisation.includes("Gestion pneu")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
