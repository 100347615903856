import { render, staticRenderFns } from "./Pneu.vue?vue&type=template&id=8533c37e&scoped=true"
import script from "./Pneu.vue?vue&type=script&lang=ts"
export * from "./Pneu.vue?vue&type=script&lang=ts"
import style0 from "./Pneu.vue?vue&type=style&index=0&id=8533c37e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8533c37e",
  null
  
)

export default component.exports